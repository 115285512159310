.details {
    font-family: 'Lato';
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: aliceblue;
  }
  
  .detailsContent {
    margin-top: 80px;
    display: flex;
    /* flex-direction: row; */
    flex: 1;
    margin-left: 10vw;
    justify-content: center;
    height: 15%;
  }
  
  .detailsImg {
    width: 600px;
    height: 600px; 
    position: relative;
    right: 25%;
  }
  
  .detailsImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .detailsInfo {
    font-family: 'Righteous';
    margin-left: 50px;
    width: 600px;
    height: 600px;
    font-size: 20px;
    font-weight: 500;
    display: flex;
    box-shadow: 5px 5px 15px -8px rgba(0,0,0,0.81);
    position: absolute;
    left: 45%;
    top: 15%;

    background-image: url(backdrop.jpg);
    background-repeat: no-repeat;
    background-size: cover;
  }

  .detailsInfo .detailsInfo-inside {
    justify-content: center;
    margin: auto;
  }

  .detailsInfo-rating{
    margin: auto;
  }

  .detailsInfo-inside-bidet{
    padding-bottom: 20px;
  }

  .detailsInfo-bidetImg{
    width: 23%;
  }
  
  .detailsInfo h2 {
    max-width: 85%;
    margin: auto;
    font-family: 'Lato';
    font-size: 48px;
    font-weight: 800;
    text-transform: uppercase;
    text-shadow: 0px 5px 3px rgba(150, 150, 150, 1);
  }

  .detailsInfo-inside button {
    padding: 6px 35px;
    margin-top: 5px;
    cursor:pointer;
    border-radius: 20px;
    font-size: 14px;
  }

  .detailInfos-Btn{
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
  }
  
  .details-input {
    width: 100%;
    margin-left: 13vw;
    margin-top: 70px;
  }
  
  .details-input label {
    text-align: left;
    display: block;
    margin-bottom: 30px;
    font-size: 42px;
  }
  .details-input span {
    font-weight: 800;
  }
  .details-input textarea{
    max-width: 75%;
    width: 75%;
    display: block;
    font-size: 18px;
  }
  
  /* vertical line */
  .hr hr {
    height: 70vh;
    border: 2px solid #595959;
  }
  
  /* for submit button */
  .submit-button{
    max-width: 75%;
    display:flex;
    
  }
  .submit-button button{
    
    color: white;
    font-size: 16px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    margin: 8px;
  
    box-shadow: 2px 2px 4px #555;
  }
  
  .gotText {
    background-color: #1184e8;
    cursor: pointer;
  }
  .noText {
    background-color: #555;
  }

  .submit-button-clear{
    background-color: #555;
    cursor: pointer !important;
  }
  
  .details-review-content {
    display: block ;
    padding-top: 50px;
    max-width: 95%;
  }
  .details-review-content-input {
    font-size: 20px;
    max-width: 80%;
    text-align: left;
    padding-top: 10px;
  }
  .details-review-content-input span{
    margin-right: 10px;
  }
  .details-review-content-inputDate{
    font-size: 11px;
    margin-left: 10px;
    color: #aeaeae;
    padding-right: 10px;
  }

  .details-review-content-replies{
    margin: 20px;
    margin-bottom: 2px;
  }

  .details-review-content-repliesText{
    margin: 0 0 30px 20px;
    font-size: 16px;
  }

  .details-review-content-button {
    cursor: pointer;
    margin-left: 10px;
    border: none;
    background-color: white;
  }
  
  .details-review-content-button:hover {
    color: red;
  }

  .submit-button{
    clear: both;
    display: block;
    text-align: left;
  }

  #react-paginate {
    margin-top : 2em
  }

  #react-paginate ul {
    display: inline-block;
    text-align : center;
    padding-left: 15px;
    padding-right: 15px;
  }
  
  #react-paginate li {
    display: inline-block;
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 5px;
  }
  .details-review-all-content{
    display: flex;
    flex-direction: column;
    text-align: start;
  }
  
  .details-reviews-content-reply{
    text-align: left;
    font-size: 14px;
    font-family:'Lato';
  }

  .details-reviews-content-replyBtn {
    border: none;
    background-color: white;
    margin-top: 20px;
    cursor: pointer;
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: bold;
    color: #aeaeae;
  }
  .details-reviews-content-replyBtn:hover {
    color: #1184e8;
  }

  .details-reviews-content-showreplyBtn{
    border: none;
    background-color: white;
    cursor: pointer;
    margin-bottom: 8px;
    font-size: 12px;
    display: block;
    font-weight: bold;
    color: #1184e8;
  }

  .submit-button-info {
    font-size: 12px;
    color: #ff5a5a;
  }
 
  /* handling different screen size */
  @media screen and (max-width: 1600px) and (max-height: 1200px) {

    .detailsImg {
      width: 450px;
      height: 450px; 
      position: relative;
      right: 25%;
    }

    .detailsInfo {
      font-family: 'Righteous';
      margin-left: 50px;
      width: 450px;
      height: 450px;
      font-size: 20px;
      font-weight: 500;
      display: flex;
      box-shadow: 5px 5px 15px -8px rgba(0,0,0,0.81);
      position: absolute;
      left: 41%;
      top: 15%;
  
      background-image: url(backdrop.jpg);
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  @media screen and (min-width: 1900px) and (min-height: 1200px) {

    .detailsImg {
      width: 450px;
      height: 450px; 
      position: relative;
      right: 25%;
    }

    .detailsInfo {
      font-family: 'Righteous';
      margin-left: 50px;
      width: 450px;
      height: 450px;
      font-size: 20px;
      font-weight: 500;
      display: flex;
      box-shadow: 5px 5px 15px -8px rgba(0,0,0,0.81);
      position: absolute;
      left: 41% !important;
      top: 29% !important;
  
      background-image: url(backdrop.jpg);
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

 