.map-container{
    /* position: relative; */
    width: 100%;
    height: 100%;
    font-family: 'Lato';
    background-color:aliceblue;
}
.map-container-title{
    display:inline-flex;
    align-items: center !important;
    margin: 10px 0;
}

.map-container-icon{
    font-size: 92px;
    font-weight: 800;
}

.map-container-title h2{
    font-weight: 400;
}
