@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');


* {
  margin: auto 0;
  padding: 0;
  margin: 0;
}

/* navbar */


