@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Righteous&display=swap);
* {
  margin: auto 0;
  padding: 0;
  margin: 0;
}

/* navbar */



.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}



/* home page */
.home{
    max-width: 95%;
    float: right;
    min-width: 900px;
    overflow: hidden;
  }
  
  .top{
    position: relative;
  }
  
  .top img{
    width: 100%;
  }
  
  .top button {
    position: absolute;
    top: 75%;
    left: 30%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #555;
    color: white;
    font-size: 24px;
    padding: 24px 48px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  
    box-shadow: 2px 4px 8px #555;
  }
  
  .top button:hover{
    background-color: #202124;
  }

  .top button:hover span{
    display: none;
  }

  .top button:hover:before{
    content: '🡲';
  }
  
  .btm h1 {
    font-family: "Lato";
    font-size: 3vw;
    font-weight: 300;
    padding: 50px;
  }
  
  .btm .btmTitle {
    font-family: "Lato";
    font-size: 3vw;
    font-weight: 700;
  }
  
  .btmImg {
    width: 23vw;
    height: 65vh;
    margin: auto;
    box-shadow: 5px 5px 20px -1px #000000
  }
  .btmContent img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .btmImg-hover img:hover{
    opacity: 0.4;
  }

  .btmImage-details{
    margin-bottom: 50px;
  }

  .btmImage-details h2{
    font-family: 'Lato';
    font-size: 20px;
    text-transform: uppercase;
    padding-top: 15px;
    font-weight: 700;
  }

  .btmImg-starRating{
    margin: auto;
  }
  
  
.carousel-container {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.carousel-wrapper {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    position: relative;
}

.carousel-content-wrapper {
    overflow: hidden;
    width: 85%;
    height: 100%;
    margin: auto;
}

.carousel-content {
    display: -webkit-flex;
    display: flex;
    transition: all 250ms linear;
    -ms-overflow-style: none;  /* hide scrollbar in IE and Edge */
    scrollbar-width: none;  /* hide scrollbar in Firefox */
}

.carousel-content.show-2 > * {
    width: 100%;
}

.carousel-content.show-3 > * {
    width: calc(100%/3);
}

.carousel-content.show-4 > * {
    width: calc(100% / 4);
}

/* hide scrollbar in webkit browser */
.carousel-content::-webkit-scrollbar, .carousel-content::-webkit-scrollbar {
    display: none;
}

.carousel-content > * {
    width: 100%;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
}

/* Buttons */
.left-arrow, .right-arrow {
    position: absolute;
    z-index: 1;
    top: 165%;
    -webkit-transform: translate(-10%, -50%);
            transform: translate(-10%, -50%);
    width: 48px;
    height: 48px;
    border-radius: 24px;
    background-color: white;
    border: 1px solid #ddd;
    cursor: pointer;
}

.left-arrow {
    left: 190px;
}
.left-arrow:hover,  .right-arrow:hover {
    color: black;
    box-shadow: 5px 5px 14px -4px #000000;
}

.right-arrow {
    right: 80px;
}

@media screen and (max-width: 1600px) and (max-height: 1200px) {
    .left-arrow, .right-arrow {
        /* position: absolute; */
        z-index: 1;
        top: 145%;
        -webkit-transform: translateY(20%,45%);
                transform: translateY(20%,45%);
        width: 48px;
        height: 48px;
        border-radius: 24px;
        background-color: white;
        border: 1px solid #ddd;
        cursor: pointer;
    }
}
/* navbar */
  .links {
    position: fixed;
    text-align: center;
    z-index: 1000;
    top:0;
    left: 0;
    width: 5% !important;
    height: 100% !important;
    font-size: 1vw;
    background-image: url(/static/media/blueBG.c337b750.jpg);
    box-shadow: 15px 5px 21px 3px rgba(0,0,0,0.8);
  }
  
  .navBar :link{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    text-decoration: none;
    font-family: "Lato";
    font-weight: 600;
    margin: 1vw;
    padding:1vw 1vh 2px;
  }

  .active{
    /* text-decoration: underline !important; */
    border-bottom: 2px solid #ffb260;
    color: #ffb260 !important
  }

  .links-btn {
    color: #aeaeae;
  }

  .links-logo{
    width:80%;
    padding-top: 30px;
    padding-bottom: 40px;
  }
.map-container{
    /* position: relative; */
    width: 100%;
    height: 100%;
    font-family: 'Lato';
    background-color:aliceblue;
}
.map-container-title{
    display:-webkit-inline-flex;
    display:inline-flex;
    -webkit-align-items: center !important;
            align-items: center !important;
    margin: 10px 0;
}

.map-container-icon{
    font-size: 92px;
    font-weight: 800;
}

.map-container-title h2{
    font-weight: 400;
}

.mapboxgl-canvas-container {
    height: 80vh;
}

.mapbox{
    width: 80% !important;
    height: 65% !important;
    margin: auto;
    margin-right: 7%;
    /* position: absolute; */
    margin-bottom: 0;
    /* box-shadow: 1px 15px 50px; */
}

.mapbox-container {
    height: 100%;
}
.InfoPopup-image {
    height : 100%;
    width : 100%;
    background-size: cover !important;
}

.InfoPopup-imageContainer{
    height : 90% !important;
    width : 90%;
    margin: auto;
}

.InfoPopup{
    font-family: 'Lato';
    text-transform: uppercase;
    font-size: 10px;
}

.InfoPopup h2{
    padding-bottom: 5px;
}
.details {
    font-family: 'Lato';
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: aliceblue;
  }
  
  .detailsContent {
    margin-top: 80px;
    display: -webkit-flex;
    display: flex;
    /* flex-direction: row; */
    -webkit-flex: 1 1;
            flex: 1 1;
    margin-left: 10vw;
    -webkit-justify-content: center;
            justify-content: center;
    height: 15%;
  }
  
  .detailsImg {
    width: 600px;
    height: 600px; 
    position: relative;
    right: 25%;
  }
  
  .detailsImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .detailsInfo {
    font-family: 'Righteous';
    margin-left: 50px;
    width: 600px;
    height: 600px;
    font-size: 20px;
    font-weight: 500;
    display: -webkit-flex;
    display: flex;
    box-shadow: 5px 5px 15px -8px rgba(0,0,0,0.81);
    position: absolute;
    left: 45%;
    top: 15%;

    background-image: url(/static/media/backdrop.ba6ab919.jpg);
    background-repeat: no-repeat;
    background-size: cover;
  }

  .detailsInfo .detailsInfo-inside {
    -webkit-justify-content: center;
            justify-content: center;
    margin: auto;
  }

  .detailsInfo-rating{
    margin: auto;
  }

  .detailsInfo-inside-bidet{
    padding-bottom: 20px;
  }

  .detailsInfo-bidetImg{
    width: 23%;
  }
  
  .detailsInfo h2 {
    max-width: 85%;
    margin: auto;
    font-family: 'Lato';
    font-size: 48px;
    font-weight: 800;
    text-transform: uppercase;
    text-shadow: 0px 5px 3px rgba(150, 150, 150, 1);
  }

  .detailsInfo-inside button {
    padding: 6px 35px;
    margin-top: 5px;
    cursor:pointer;
    border-radius: 20px;
    font-size: 14px;
  }

  .detailInfos-Btn{
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
  }
  
  .details-input {
    width: 100%;
    margin-left: 13vw;
    margin-top: 70px;
  }
  
  .details-input label {
    text-align: left;
    display: block;
    margin-bottom: 30px;
    font-size: 42px;
  }
  .details-input span {
    font-weight: 800;
  }
  .details-input textarea{
    max-width: 75%;
    width: 75%;
    display: block;
    font-size: 18px;
  }
  
  /* vertical line */
  .hr hr {
    height: 70vh;
    border: 2px solid #595959;
  }
  
  /* for submit button */
  .submit-button{
    max-width: 75%;
    display:-webkit-flex;
    display:flex;
    
  }
  .submit-button button{
    
    color: white;
    font-size: 16px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    margin: 8px;
  
    box-shadow: 2px 2px 4px #555;
  }
  
  .gotText {
    background-color: #1184e8;
    cursor: pointer;
  }
  .noText {
    background-color: #555;
  }

  .submit-button-clear{
    background-color: #555;
    cursor: pointer !important;
  }
  
  .details-review-content {
    display: block ;
    padding-top: 50px;
    max-width: 95%;
  }
  .details-review-content-input {
    font-size: 20px;
    max-width: 80%;
    text-align: left;
    padding-top: 10px;
  }
  .details-review-content-input span{
    margin-right: 10px;
  }
  .details-review-content-inputDate{
    font-size: 11px;
    margin-left: 10px;
    color: #aeaeae;
    padding-right: 10px;
  }

  .details-review-content-replies{
    margin: 20px;
    margin-bottom: 2px;
  }

  .details-review-content-repliesText{
    margin: 0 0 30px 20px;
    font-size: 16px;
  }

  .details-review-content-button {
    cursor: pointer;
    margin-left: 10px;
    border: none;
    background-color: white;
  }
  
  .details-review-content-button:hover {
    color: red;
  }

  .submit-button{
    clear: both;
    display: block;
    text-align: left;
  }

  #react-paginate {
    margin-top : 2em
  }

  #react-paginate ul {
    display: inline-block;
    text-align : center;
    padding-left: 15px;
    padding-right: 15px;
  }
  
  #react-paginate li {
    display: inline-block;
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 5px;
  }
  .details-review-all-content{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    text-align: start;
  }
  
  .details-reviews-content-reply{
    text-align: left;
    font-size: 14px;
    font-family:'Lato';
  }

  .details-reviews-content-replyBtn {
    border: none;
    background-color: white;
    margin-top: 20px;
    cursor: pointer;
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: bold;
    color: #aeaeae;
  }
  .details-reviews-content-replyBtn:hover {
    color: #1184e8;
  }

  .details-reviews-content-showreplyBtn{
    border: none;
    background-color: white;
    cursor: pointer;
    margin-bottom: 8px;
    font-size: 12px;
    display: block;
    font-weight: bold;
    color: #1184e8;
  }

  .submit-button-info {
    font-size: 12px;
    color: #ff5a5a;
  }
 
  /* handling different screen size */
  @media screen and (max-width: 1600px) and (max-height: 1200px) {

    .detailsImg {
      width: 450px;
      height: 450px; 
      position: relative;
      right: 25%;
    }

    .detailsInfo {
      font-family: 'Righteous';
      margin-left: 50px;
      width: 450px;
      height: 450px;
      font-size: 20px;
      font-weight: 500;
      display: -webkit-flex;
      display: flex;
      box-shadow: 5px 5px 15px -8px rgba(0,0,0,0.81);
      position: absolute;
      left: 41%;
      top: 15%;
  
      background-image: url(/static/media/backdrop.ba6ab919.jpg);
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  @media screen and (min-width: 1900px) and (min-height: 1200px) {

    .detailsImg {
      width: 450px;
      height: 450px; 
      position: relative;
      right: 25%;
    }

    .detailsInfo {
      font-family: 'Righteous';
      margin-left: 50px;
      width: 450px;
      height: 450px;
      font-size: 20px;
      font-weight: 500;
      display: -webkit-flex;
      display: flex;
      box-shadow: 5px 5px 15px -8px rgba(0,0,0,0.81);
      position: absolute;
      left: 41% !important;
      top: 29% !important;
  
      background-image: url(/static/media/backdrop.ba6ab919.jpg);
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

 
/* footer */
.footer{
    font-family: 'Lato';
    margin: 10px;
    /* position:absolute; */
    left:0;
    right:0;
    clear: both !important;
  }
.notfound{
    margin:auto;
    padding-top: 200px;
    margin-bottom: 90px;
}

.notfound-img{
    height: 100%;
    width: 60%;
    box-shadow: 0px 15px 50px;
    margin-bottom: 60px;
}

.notfound-link{
    font-family: "Lato";
    font-weight: 600;
    text-decoration: none;
    color: black;
}
